<script>
    import Layout from "./Layout.svelte";
    import { getContext } from "svelte";
    import { Container, Row, Col, Button } from "sveltestrap";
    import Fa from 'svelte-fa/src/fa.svelte';
    import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
    import { init, __ } from "../i18n";

    const { catalog } = getContext("global.stores");
    init($catalog);

    export let title;
    export let error;
</script>

<svelte:head>
    <title>{title}</title>
</svelte:head>

<local>
    <Layout>
        <Container>
            <!--<Row class="code">
                <Col><h1>{error.status}</h1></Col>
            </Row>-->
            <Row class="code">
                <Col><Fa icon={faCircleXmark} class="fa-danger"></Fa></Col>
            </Row>
            <Row class="message">
                <Col><h3>{error.msg}</h3></Col>
            </Row>
            <Row class="mt-3">
                <a href="/"><Button color="custom" class="my-2">{__("HOME_BTN")}</Button></a>
            </Row>
        </Container>
    </Layout>
</local>

<style>
    :global(body, html) {
        background: none;
        background-color: var(--dashboard-bg-color);
        text-align: center;
    }
    local :global(.code) {
        font-size: 3em;
        margin-top: calc(50vh - 130px);
        color: #ed5565;
    }
    local :global(.message h4) {
        font-weight: 400;
    }
    local :global(button) {
        color: var(--login-button-text-color) !important;
        background-color: var(--login-button-bg-color) !important;
        border-color: var(--login-button-bg-color) !important;
    }
    local :global(button:hover) {
        background-color: var(--login-button-hover-bg-color) !important;
        border-color: var(--login-button-hover-bg-color) !important;
    }
    local :global(button:active) {
        border-style: inset;
    }
</style>