
import ViewGlobals from '../../node_modules/express-svelte/lib/components/ViewGlobals.svelte';
import ViewComponent from '../../views/Error.svelte';
const [ target = document.body ] = document.getElementsByClassName('view-target');
const [ anchor = null ] = document.getElementsByClassName('view-anchor');

const globalProps = window._GLOBAL_PROPS_ || {};
const globalStores = window._GLOBAL_STORES_ || {};
const props = window._PROPS_ || {};

new ViewGlobals({
    target,
    anchor,
    hydrate: true,
    props: {
        globalProps,
        globalStores,
        component: ViewComponent,
        props
    }
});